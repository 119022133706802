<template>
  <div id="app">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-12" style="font-weight:700;font-size:20px;letter-spacing:6px;">
                                Dashboard Monitoring Kekuatan
                                <span class="pull-right">
                                    <div class="row">
                                    <div class="col-md-4">
                                        <button class="btn btn-info btn-sm btn-padding" @click="toCredit"><i class="fa fa-money"></i></button>        
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-warning btn-sm btn-padding" @click="toChangePassword"><i class="fa fa-key"></i></button>        
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-danger btn-sm btn-padding" @click="toSignOut"><i class="fa fa-power-off"></i></button>
                                    </div>
                                </div>
                                </span>
                            </div>
                            <!--
                            <div class="col-md-1">
                                <div class="row">
                                    <div class="col-md-4" style="padding:0px;">
                                        <button class="btn btn-info btn-sm btn-padding" @click="toCredit"><i class="fa fa-money"></i></button>        
                                    </div>
                                    <div class="col-md-4" style="padding:0px;">
                                        <button class="btn btn-warning btn-sm btn-padding" @click="toChangePassword"><i class="fa fa-key"></i></button>        
                                    </div>
                                    <div class="col-md-4" style="padding:0px;">
                                        <button class="btn btn-danger btn-sm btn-padding" @click="toSignOut"><i class="fa fa-power-off"></i></button>
                                    </div>
                                </div>
                            </div>
                            -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                             <!-- begin filter -->
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-inline">
                                            <label class="text-filter">Kode Area/Cabang</label>
                                            <Select2 class="col-sm-8 mr-2" v-model="list.area" :options="areaopt" :settings="{width:'100%', allowClear: true}" @change="loadPostsData" />
                                            <!-- <select v-model="list.area" class="form-control col-sm-8 mr-2" @change="loadPostsData">
                                                <option v-for="option in areaopt" v-bind:key="option.id" v-bind:value="option.id">
                                                    {{ option.text }}
                                                </option>
                                            </select>-->
                                            <!-- <input v-model="list.area_name" type="text" class="form-control col-sm-6"> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-inline">
                                            <label class="text-filter mr-5">Kekuatan</label>
                                            <Select2 class="col-sm-5" style="padding:0px;" v-model="list.strength" :options="strengthopt" :settings="{width:'100%', allowClear: true}" @change="strengthUpdate" />
                                            <!-- <input v-model="list.strength" type="text" class="form-control col-sm-4 text-center" @input="strengthUpdate"> -->
                                            <!-- <select v-model="list.strength" class="form-control col-sm-5 mr-1" @change="strengthUpdate">
                                                <option v-for="option in strengthopt" v-bind:key="option.id" v-bind:value="option.id">
                                                    {{ option.text }}
                                                </option>
                                            </select>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-inline">
                                    <label class="text-filter">Group Nasabah/Ekosistem/Kementerian</label>
                                    <Select2 class="col-sm-6" v-model="list.customer_group" :options="customergroupopt" :settings="{width:'100%', allowClear: true}" @change="loadPostsData" />
                                    <!-- <select v-model="list.customer_group" class="form-control col-sm-6 mr-2" @change="loadPostsData">
                                        <option v-for="option in customergroupopt" v-bind:key="option.id" v-bind:value="option.id">
                                            {{ option.text }}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                            <!-- end filter -->

                            <!-- begin acquisition -->
                            <div class="col-md-12 mt-5">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h5 class="card-title mb-0">Dashboard Nasabah Akuisisi</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="panel panel-default">
                                                        <div class="panel-heading text-header-holding">Akuisisi Nasabah Perusahaan dan Non Payroll</div>
                                                        <div class="panel-body">
                                                            <vue-gauge v-if="valuechainsb" :refid="'valuechains'" :options=dataChartValueChains></vue-gauge>
                                                        </div>
                                                        <div class="panel-footer">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <!--
                                                                    <div class="col-md-8 text-note text-left">Nasabah yang telah terakuisisi</div>
                                                                    <div class="col-md-4 text-note text-right">{{valuechains.acquisition}}</div>
                                                                    <div class="col-md-8 text-note text-left">Potensi Nasabah yang bisa diakuisisi</div>
                                                                    <div class="col-md-4 text-note text-right">{{valuechains.prospect}}</div>
                                                                    -->
                                                                    <div class="col-md-12 text-note text-left text-wrap">Nasabah yang telah terakuisisi 
                                                                        <span class="pull-right">{{valuechains.acquisition}}</span>
                                                                    </div>
                                                                    <div class="col-md-12 text-note text-left">
                                                                        Potensi Nasabah yang bisa diakuisisi
                                                                        <span class="pull-right">{{valuechains.prospect}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel panel-default mt-3">
                                                        <div class="panel-heading text-header-holding">Akuisisi Nasabah Payroll</div>
                                                        <div class="panel-body">
                                                            <vue-gauge  v-if="payrollb" :refid="'payroll'" :options=dataChartPayroll></vue-gauge>
                                                            </div>
                                                        <div class="panel-footer">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <!--
                                                                    <div class="col-md-8 text-note text-left">Payroll yang telah terakuisisi</div>
                                                                    <div class="col-md-4 text-note text-right">{{payroll.acquisition}}</div>
                                                                    <div class="col-md-8 text-note text-left">Potensi Payroll yang bisa diakuisisi</div>
                                                                    <div class="col-md-4 text-note text-right">{{payroll.prospect}}</div>
                                                                    -->
                                                                    <div class="col-md-12 text-note text-left text-wrap">Payroll yang telah terakuisisi 
                                                                        <span class="pull-right">{{payroll.acquisition}}</span>
                                                                    </div>
                                                                    <div class="col-md-12 text-note text-left">
                                                                        Potensi Payroll yang bisa diakuisisi
                                                                        <span class="pull-right">{{payroll.prospect}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <h5 class="card-title mb-0">Dashboard Akuisisi Product Holding</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding">Akuisisi Product KSM (Kredit Konsumsi)</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="ksmb" :refid="'ksm'" :options=dataChartKSM></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                            <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{ksm.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{ksm.prospect}}</div>
                                                                            -->
                                                                             <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{ksm.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{ksm.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding padding-kpr">Akuisisi Product KPR</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="kprb" :refid="'kpr'" :options=dataChartKPR></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                            <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{kpr.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{kpr.prospect}}</div>
                                                                            -->
                                                                            <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{kpr.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{kpr.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding">Akuisisi Product Kartu Kredit</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="creditcardb" :refid="'kartukredit'" :options=dataChartCreditCard></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                            <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{creditcard.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{creditcard.prospect}}</div> 
                                                                            -->
                                                                            <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{creditcard.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{creditcard.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 mt-3">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding">Akuisisi Product MIB</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="mibb" :refid="'mib'" :options=dataChartMIB></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                            <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mib.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mib.prospect}}</div>
                                                                            -->
                                                                            <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{mib.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{mib.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 mt-3">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding">Akuisisi Product MCM</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="mcmb" :refid="'mcm'" :options=dataChartMCM></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                            <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mcm.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mcm.prospect}}</div>
                                                                            -->
                                                                            <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{mcm.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{mcm.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 mt-3">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding">Akuisisi Product Mandol</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="mandolb" :refid="'mandol'" :options=dataChartMandol></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                                <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mandol.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mandol.prospect}}</div>
                                                                            -->
                                                                            <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{mandol.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{mandol.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 mt-3">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding">Akuisisi Product MTR</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="mtrb" :refid="'mtr'" :options=dataChartMTR></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                            <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mtr.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{mtr.prospect}}</div>
                                                                            -->
                                                                            <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{mtr.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{mtr.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       <div class="col-md-4 mt-3">
                                                            <div class="panel panel-default">
                                                                <div class="panel-heading text-header-holding">Akuisisi Product Deposito</div>
                                                                <div class="panel-body">
                                                                    <vue-gauge v-if="depob" :refid="'depo'" :options=dataChartDepo></vue-gauge>
                                                                </div>
                                                                <div class="panel-footer">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <!--
                                                                            <div class="col-md-8 text-note text-left">Telah terakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{depo.acquisition}}</div>
                                                                            <div class="col-md-8 text-note text-left">Potensi untuk diakuisisi</div>
                                                                            <div class="col-md-4 text-note text-right">{{depo.prospect}}</div>
                                                                            -->
                                                                            <div class="col-md-12 text-note text-left text-wrap">Telah terakuisisi 
                                                                                <span class="pull-right">{{depo.acquisition}}</span>
                                                                            </div>
                                                                            <div class="col-md-12 text-note text-left">
                                                                                Potensi untuk diakuisisi
                                                                                <span class="pull-right">{{depo.prospect}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end acquisition -->
                            <div class="col-md-12 mt-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h5 class="card-title mb-0">Dashboard Monitoring Leads</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-4 ml-auto">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">Leads Assign</div>
                                                            <div class="panel-body">
                                                                <vue-gauge v-if="assignb" :refid="'assign'" :options=dataChartAssign></vue-gauge>
                                                            </div>
                                                            <div class="panel-footer">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <!--
                                                                        <div class="offset-md-2 col-md-6 text-left">Jumlah yang telah di assign sebanyak</div>
                                                                        <div class="col-md-2 col-md-offset-2 text-center">{{assign.leads}}</div>
                                                                        -->
                                                                        <div class="col-md-12 text-left text-wrap">Jumlah yang telah di assign sebanyak
                                                                            <span class="pull-right">{{assign.leads}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mr-auto">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">Leads Contacted</div>
                                                            <div class="panel-body">
                                                                <vue-gauge v-if="contactedb" :refid="'contacted'" :options=dataChartContacted></vue-gauge>
                                                            </div>
                                                            <div class="panel-footer">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <!--
                                                                        <div class="offset-md-2 col-md-6 text-left">Jumlah yang telah dihubungi sebanyak</div>
                                                                        <div class="col-md-2 col-md-offset-2 text-center">{{contacted.leads}}</div>
                                                                        -->
                                                                        <div class="col-md-12 text-left text-wrap">Jumlah yang telah dihubungi sebanyak
                                                                            <span class="pull-right">{{contacted.leads}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 mt-5">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">Rate Responses "Menerima"</div>
                                                            <div class="panel-body">
                                                                <!-- <vue-gauge v-if="acceptedb" :refid="'accepted'" :options=dataChartAccepted></vue-gauge> -->
                                                                <div v-if="acceptedb" class="offset-md-2 col-md-8 col-md-offset-2">
                                                                    <div class="responses-box">
                                                                        <div class="responses-text">{{accepted.percent_leads}}%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="panel-footer">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="offset-md-1 col-md-7 text-note text-left">Jumlah Responses "Menerima"</div>
                                                                        <div class="col-md-3 text-note text-right">{{accepted.leads}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mt-5">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">Rate Responses "Follow Up"</div>
                                                            <div class="panel-body">
                                                                <!-- <vue-gauge v-if="followupb" :refid="'followup'" :options=dataChartFollowup></vue-gauge>-->
                                                                <div v-if="followupb" class="offset-md-2 col-md-8 col-md-offset-2">
                                                                    <div class="responses-box">
                                                                        <div class="responses-text">{{followup.percent_leads}}%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="panel-footer">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="offset-md-1 col-md-7 text-note text-left">Jumlah Responses "Follow Up"</div>
                                                                        <div class="col-md-3 text-note text-right">{{followup.leads}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mt-5">
                                                        <div class="panel panel-default">
                                                            <div class="panel-heading">Rate Response "Menolak"</div>
                                                            <div class="panel-body">
                                                                <!-- <vue-gauge v-if="refusedb" :refid="'refused'" :options=dataChartRefused></vue-gauge> -->
                                                                <div v-if="refusedb" class="offset-md-2 col-md-8 col-md-offset-2">
                                                                    <div class="responses-box">
                                                                        <div class="responses-text">{{refused.percent_leads}}%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="panel-footer">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="offset-md-1 col-md-7 text-note text-left">Jumlah Response "Menolak"</div>
                                                                        <div class="col-md-3 text-note text-right">{{refused.leads}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// Element inside which you want to see the chart
//import downloadexcel from "vue-json-excel";
import axios from 'axios' //IMPORT AXIO
import VueGauge from 'vue-gauge';
import Select2 from 'v-select2-component';
import VueScreenSize from 'vue-screen-size'


// let GaugeChart = require('../assets/js/bundle.js');

export default {
  name: 'App',
  mixins: [VueScreenSize.VueScreenSizeMixin],
    components : {VueGauge, Select2},
    created() {
        //MAKA AKAN MENJALANKAN FUNGSI BERIKUT
        this.loadPostsData();
        this.areaList();
        this.customerGroupList();
        this.strengthList();
        if (this.$vssWidth <= 1024 && this.$vssWidth > 450) {
            this.dataChartValueChains.chartWidth = 140;
            this.dataChartPayroll.chartWidth = 140;
            this.dataChartCreditCard.chartWidth = 130;
            this.dataChartKSM.chartWidth = 130;
            this.dataChartKPR.chartWidth = 130;
            this.dataChartMIB.chartWidth = 130;
            this.dataChartMCM.chartWidth = 130;
            this.dataChartMandol.chartWidth = 130;
            this.dataChartMTR.chartWidth = 130;
            this.dataChartDepo.chartWidth = 130;
            this.sizeFont = 'font-size:xx-small';
        } else {
            this.dataChartCreditCard.chartWidth = 200;
            this.dataChartKSM.chartWidth = 200;
            this.dataChartKPR.chartWidth = 200;
            this.dataChartMIB.chartWidth = 200;
            this.dataChartMCM.chartWidth = 200;
            this.dataChartMandol.chartWidth = 200;
            this.dataChartMTR.chartWidth = 200;
            this.dataChartDepo.chartWidth = 200;
        }
        // alert(this.$vssWidth);
    },
    data() {
        return {
            areaopt : [{ text: '---', id: '' }],
            customergroupopt : [{ text: '---', id: '' }],
			strengthopt : [{ text: '---', id: '' }],
            search: '',
            sizeFont : 'font-size:13px;',
            list : {
                area:'',
                strength : '',
                customer_group : ''
            },
            valuechainsb : false,
            payrollb : false,
            ksmb : false,
            kprb : false,
            creditcardb : false,
            mibb : false,
            mcmb : false,
            mandolb : false,
            mtrb : false,
            depob : false,
            assignb : false,
            contactedb : false,
            acceptedb : false,
            followupb : false,
            refusedb : false,
            valuechains : {},
            payroll : {},
            ksm : {},
            kpr : {},
            creditcard : {},
            mib : {},
            mcm : {},
            mandol : {},
            mtr : {},
            depo : {},
            assign : {},
            contacted : {},
            accepted : {},
            followup : {},
            refused : {},
            dataChartValueChains : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%'
            },
            dataChartPayroll : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%'
            },
            dataChartKSM : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartKPR : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 180
            },
            dataChartCreditCard : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartMIB : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartMCM : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartMandol : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartMTR : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartDepo : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartAssign : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartContacted : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartAccepted : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartFollowup : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
            dataChartRefused : {
                'needleColor':'black',
                'arcColors':['rgb(255,84,84)','rgb(239,214,19)','rgb(61,204,91)'],
                'arcDelimiters':[90,95],
                'rangeLabel':['0','100'],
                'needleStartValue':0,
                'needleValue':85,
                'centralLabel':'85%',
                'chartWidth' : 200
            },
        }
    },
    mounted () {
        this.$data.areaopt = [{ text: '---', id: '' }];
        this.$data.customergroupopt = [{ text: '---', id: '' }];
    },
    methods: {
        loadPostsData() {
            this.getValueChains();
            this.getPayroll();
            this.getKSM();
            this.getKPR();
            this.getCreditCard();
            this.getMIB();
            this.getMCM();
            this.getMandol();
            this.getMTR();
            this.getDepo();
            this.getAssign();
            this.getContacted();
            this.getAccepted();
            this.getFollowUp();
            this.getRefused();
        },
        areaList() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/area.php',{},
            {
                headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then(function (response) {
                vm.areaopt = response.data;
                console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.areaopt = [];
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

                // setTimeout(() => {
                //     this.reqList();
                // }, 6000);
            });
        },
        customerGroupList() {
            let vm = this;
            this.filter = {strength:vm.list.strength};
            axios.post(this.$root.baseUrlApi+'functions/customer_group.php',this.filter,
            {
                headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then(function (response) {
                vm.customergroupopt = response.data;
                console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.customergroupopt = [];
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

                // setTimeout(() => {
                //     this.reqList();
                // }, 6000);
            });
        },
        getValueChains() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/value_chain.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then(function (response) {
                vm.valuechains = response.data;
                vm.dataChartValueChains.needleValue = response.data.percent_acquisition;
                vm.dataChartValueChains.centralLabel=response.data.percent_acquisition+'%';
                vm.valuechainsb = false;
                 vm.$nextTick (() => {
                    vm.valuechainsb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.valuechains = {};
                vm.valuechainsb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

                // setTimeout(() => {
                //     this.reqList();
                // }, 6000);
            });
        },
        getPayroll() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/payroll.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.payroll = response.data;
                vm.dataChartPayroll.needleValue = response.data.percent_acquisition;
                vm.dataChartPayroll.centralLabel=response.data.percent_acquisition+'%';
                vm.payrollb = false;
                 vm.$nextTick (() => {
                    vm.payrollb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.payroll = {};
                vm.payrollb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getKSM() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/ksm.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.ksm = response.data;
                vm.dataChartKSM.needleValue = response.data.percent_acquisition;
                vm.dataChartKSM.centralLabel=response.data.percent_acquisition+'%';
                vm.ksmb = false;
                 vm.$nextTick (() => {
                    vm.ksmb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.ksm = {};
                vm.ksmb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getKPR() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/kpr.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.kpr = response.data;
                vm.dataChartKPR.needleValue = response.data.percent_acquisition;
                vm.dataChartKPR.centralLabel=response.data.percent_acquisition+'%';
                vm.kprb = false;
                 vm.$nextTick (() => {
                    vm.kprb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.kpr = {};
                vm.kprb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getCreditCard() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/credit_card.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.creditcard = response.data;
                vm.dataChartCreditCard.needleValue = response.data.percent_acquisition;
                vm.dataChartCreditCard.centralLabel= response.data.percent_acquisition+'%';
                vm.creditcardb = false;
                 vm.$nextTick (() => {
                    vm.creditcardb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.creditcard = {};
                vm.creditcardb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getMIB() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/mib.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.mib = response.data;
                vm.dataChartMIB.needleValue = response.data.percent_acquisition;
                vm.dataChartMIB.centralLabel= response.data.percent_acquisition+'%';
                vm.mibb = false;
                 vm.$nextTick (() => {
                    vm.mibb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.mib = {};
                vm.mibb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getMCM() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/mcm.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.mcm = response.data;
                vm.dataChartMCM.needleValue = response.data.percent_acquisition;
                vm.dataChartMCM.centralLabel = response.data.percent_acquisition+'%';
                vm.mcmb = false;
                 vm.$nextTick (() => {
                    vm.mcmb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.mcm = {};
                vm.mcmb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getMandol() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/mandol.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.mandol = response.data;
                vm.dataChartMandol.needleValue = response.data.percent_acquisition;
                vm.dataChartMandol.centralLabel = response.data.percent_acquisition+'%';
                vm.mandolb = false;
                 vm.$nextTick (() => {
                    vm.mandolb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.mandol = {};
                vm.mandolb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getMTR() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/mtr.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.mtr = response.data;
                vm.dataChartMTR.needleValue = response.data.percent_acquisition;
                vm.dataChartMTR.centralLabel = response.data.percent_acquisition+'%';
                vm.mtrb = false;
                 vm.$nextTick (() => {
                    vm.mtrb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.mtr = {};
                vm.mtrb = false;
                if (!error.response) {
                    // alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getDepo() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/acquisition_customer/depo.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.depo = response.data;
                vm.dataChartDepo.needleValue = response.data.percent_acquisition;
                vm.dataChartDepo.centralLabel = response.data.percent_acquisition+'%';
                vm.depob = false;
                 vm.$nextTick (() => {
                    vm.depob = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.depo = {};
                vm.depob = false;
                if (!error.response) {
                    // alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getAssign() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/leads/assign.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.assign = response.data;
                vm.dataChartAssign.needleValue = response.data.percent_leads;
                vm.dataChartAssign.centralLabel = response.data.percent_leads+'%';
                vm.assignb = false;
                 vm.$nextTick (() => {
                    vm.assignb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.assign = {};
                vm.assignb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getContacted() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/leads/contacted.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.contacted = response.data;
                vm.dataChartContacted.needleValue = response.data.percent_leads;
                vm.dataChartContacted.centralLabel = response.data.percent_leads+'%';
                vm.contactedb = false;
                 vm.$nextTick (() => {
                    vm.contactedb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.contacted = {};
                vm.contactedb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getAccepted() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/leads/accepted.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.accepted = response.data;
                vm.dataChartAccepted.needleValue = response.data.percent_leads;
                vm.dataChartAccepted.centralLabel = response.data.percent_leads+'%';
                vm.acceptedb = false;
                 vm.$nextTick (() => {
                    vm.acceptedb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.accepted = {};
                vm.acceptedb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getFollowUp(){
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/leads/follow_up.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.followup = response.data;
                vm.dataChartFollowup.needleValue = response.data.percent_leads;
                vm.dataChartFollowup.centralLabel = response.data.percent_leads+'%';
                vm.followupb = false;
                 vm.$nextTick (() => {
                    vm.followupb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.followup = {};
                vm.followupb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        getRefused() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/leads/refused.php', vm.list,
            {
                headers : {'Authorization' : vm.$parent.accessToken}
            }).then(function (response) {
                vm.refused = response.data;
                vm.dataChartRefused.needleValue = response.data.percent_leads;
                vm.dataChartRefused.centralLabel = response.data.percent_leads+'%';
                vm.refusedb = false;
                 vm.$nextTick (() => {
                    vm.refusedb = true;
                });
                //console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.refused = {};
                vm.refusedb = false;
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

            });
        },
        strengthUpdate() {
            // alert(this.list.strength);
            // this.list.strength = e.target.value;
            this.list.customer_group = '';
            this.customerGroupList();
            this.loadPostsData();
        },
        toSignOut(e) {
            e.preventDefault();
            localStorage.removeItem('accessTokenDashboard');
			localStorage.removeItem('authenticatedDashboard');
            this.$parent.$data.authenticated = false;
            this.$parent.$data.accessToken = '';
            this.$parent.$data.list = {};
            this.$data.areaopt = [{ text: '---', id: '' }];
            this.$data.customergroupopt = [{ text: '---', id: '' }];
            this.$forceUpdate();
		},
		strengthList() {
			let vm = this;
            axios.post(this.$root.baseUrlApi+'functions/strength.php',{},
            {
                headers : {'Authorization' : vm.$parent.accessToken} //{ 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then(function (response) {
                vm.strengthopt = response.data;
                console.log(response);
            }).catch(function (error) {
                console.log(error);
                vm.strengthopt = [{ text: '---', id: '' }];
                if (!error.response) {
                    alert("Please check your connection and try again");
                } else {
                    if (error.response.data.message) {
                        alert(error.response.data.message);
                    } else {
                        alert("error occured in server");
                    }
                }

                // setTimeout(() => {
                //     this.reqList();
                // }, 6000);
            });
        },
        toChangePassword(e) {
            e.preventDefault();
            this.$router.push({path : '/changepassword' });
		},
        toCredit(e){
            e.preventDefault();
            this.$router.push({path : '/credit' });
        }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.text-header-holding {
    font-size: 13px;
}

.padding-kpr {
    padding : none;
}

@media (min-width: 768px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 768px !important;
    }

    .responses-text {
        padding : 33px;
        font-size: 18px;
    }

    .responses-box {
        min-height : 93px;
    }

    .text-header-holding, .text-note {
        font-size: xx-small;
    }

    .padding-kpr {
        padding : 7px;
    }

    h5 {
        font-size:1rem;
    }
}

@media (min-width: 1024px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1024px !important;
    }

    .responses-text {
        padding : 33px;
        font-size: 18px;
    }

    .responses-box {
        min-height : 93px;
    }

    .text-header-holding, .text-note {
        font-size: xx-small;
    }

    .padding-kpr {
        padding : 0px;
    }

    h5 {
        font-size:1rem;
    }
}

@media (min-width: 1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px !important;
    }
    .text-header-holding {
        font-size: 13px;
    }

    .padding-kpr {
        padding : 0px;
    }

    .responses-text {
        padding : 56px;
        font-size: 32px;
    }

    .responses-box {
        min-height : 160px;
    }

    h5 {
        font-size:1.25rem;
    }

    .text-note {
        font-size: 11px;
    }
}

@media (min-width: 1280px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1280px !important;
    }
    .text-header-holding {
        font-size: 13px;
    }

    .padding-kpr {
        padding : 0px;
    }

    .responses-text {
        padding : 56px;
        font-size: 32px;
    }

    .responses-box {
        min-height : 160px;
    }

    h5 {
        font-size:1.25rem;
    }

    .text-note {
        font-size: 11px;
    }
}

@media (min-width: 1365px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1365px !important;
    }

    .text-header-holding {
        font-size: 13px;
    }

    .padding-kpr {
        padding : 0px;
    }

    .responses-text {
        padding : 56px;
        font-size: 32px;
    }

    .responses-box {
        min-height : 160px;
    }

    h5 {
        font-size:1.25rem;
    }

    .text-note {
        font-size: 11px;
    }
}

.container {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important; 
    margin-right: auto;
    margin-left: auto;
    overflow-x: hidden;
}
</style>
